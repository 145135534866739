import AppBase from './apps/app';
import SystemBase from './apps/system';

const Config: ConfigType = {
    appName: "MILC",
    domain: {
        account: "milc.sharo-dev.com",
        contents: "milc.sharo-dev.com",
    },
    endpoint: {
        account: "https://milc.sharo-dev.com/account/",
        contents: "https://milc.sharo-dev.com/contents/",
        contentsWs: "https://milc.sharo-dev.com",
    },
    debug: {
        sfu: true,
        layout: false,
        engine: false,
        ws: true,
        api: false,
    },
    application: {
        system: {
            launch: async () => {
                // return new SystemApp();

                const app = await import('@/apps/system/app');
                return new app.default();
            }
        },
        manage: {
            launch: async () => {
                const app = await import('@manage/app');
                return new app.default();
            },
            system: async () => new (await import("@/apps/manage/system")).default(),
        },
        account: {
            launch: async () => {
                const app = await import('@account/app');
                return new app.default();
            }
        },
        carry: {
            launch: async () => {
                const app = await import('@apps/carry/app');
                return new app.default();
            },
            system: async () => new (await import("@/apps/carry/system")).default(),
        },
        creation: {
            launch: async () => {
                const app = await import('@/apps/creation/app');
                return new app.default();
            },
            system: async () => new (await import("@/apps/creation/system")).default(),
        },
        miniApp: {
            launch: async () => {
                const app = await import('@/apps/mini-app/app');
                return new app.default();
            },
            system: async () => new (await import("@/apps/mini-app/system")).default(),
        },
    }
}

type ConfigType = {
    appName: string,
    domain: {
        account: string,
        contents: string
    },
    endpoint: {
        account: string,
        contents: string,
        contentsWs: string,
    },
    startup?: {
        [key: string]: string
    }
    debug: { [key: string]: boolean },
    application: {
        [key: string]: {
            launch: () => Promise<AppBase>,
            system?: () => Promise<SystemBase>
            hide?: boolean,
        }
    }
}

var configInstance = Config;

export async function initializeConfig() {

    const manifest = await (await fetch("/manifest.json")).json();

    configInstance = deepAssign(configInstance, manifest);
    console.log("Config", configInstance)
}

function deepAssign(parent: any, child: any) {


    for (const key of Object.keys(child))
        if (typeof (child[key]) === "object") {
            if (typeof (child[key].length) === "number") {  // 子が配列の場合
                if (typeof (parent[key].length) === "number")   // 親も配列だった場合
                    parent[key] = [...(parent[key] as any[]), ...(child[key] as any[])];
                else                                            // 親はオブジェクトだった場合
                    parent[key] = child[key];
            }
            else {                                          // 子がオブジェクトの場合
                if (typeof (parent[key]) === "object")      // 親もオブジェクトだった場合
                    parent[key] = deepAssign(parent[key], child[key]);
                else                                        // 親は配列だった場合
                    parent[key] = child[key];
            }
        } else
            parent[key] = child[key];

        return parent;

}

export function getConfig() {
    return configInstance;
}